@import (once) './breakpoints.less';
@import (once) '../../theme.less';

h1 {
  font-size: 2rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @theme-color;
  border-color: @theme-color;
}

.ant-btn-primary {
  background-color: @theme-color;
  border-color: @theme-color;
  &:hover {
    color: white !important;
    background-color: @theme-color-highlight;
    border-color: @theme-color-highlight;
  }
  &:focus {
    background-color: @theme-color !important;
    border-color: @theme-color !important;
    color: white !important;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: @theme-color-highlight;
  border-color: @theme-color-highlight;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-dark .ant-menu-submenu-selected {
  background-color: @theme-color;
}

.ant-pagination-item-active a {
  color: @theme-color;
  &:hover {
    color: @theme-color-highlight;
  }
}

.ant-pagination-item-active {
  border-color: @theme-color;
  &:hover {
    border-color: @theme-color-highlight;
  }
}

.swal2-styled.swal2-confirm {
  background-color: @theme-color;
  transition: 0.2s background-color ease-out;
  &:hover {
    transition: 0.2s background-color ease-in;
    background-color: @theme-color-highlight;
  }
  &:focus {
    box-shadow: none;
  }
}

.ant-menu-inline-collapsed {
  width: 80px !important;
}

.ant-layout,
.ant-layout-footer {
  background-color: floralwhite !important;
}

.app-container {
  min-height: 100vh;

  &-body {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 85px;
    margin-bottom: 40px;
  }

  &-content {
    min-height: 100px;
    background-color: white;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;

    -webkit-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.75);

    &-title {
      font-size: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid @theme-color;
      padding-bottom: 5px;

      .ant-select {
        margin-left: 10px;
      }
    }

    #c1,
    #c2 {
      min-height: 300px;
    }
  }
}

.ant-modal {
  .ant-btn-cancel {
    float: left;
  }

  .ant-modal-title {
    text-transform: capitalize;
  }
}

.ant-table-cell {
  &.bg-success,
  &.bg-warning {
    .ant-dropdown-link {
      color: white;
      text-decoration: underline;
    }
  }
}

.ant-ribbon {
  font-size: 9px;
  &.in-active {
    display: none;
  }
}

.ant-ribbon-wrapper {
  .ant-row {
    border: 1px solid #eee;
  }
}

body {
  background: #001529;
}

.form-header {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaa;
  height: 300px;

  @media @large-desktop-down {
    height: 200px;
  }

  @media @desktop-down {
    height: 150px;
  }

  @media @phone-down {
    height: 100px;
  }

  div {
    position: relative;

    p {
      font-size: 3em;
      color: beige;
      position: absolute;
      top: -180px;
      margin-left: 12%;
      width: 100%;
      text-align: center;
      z-index: 9999;

      @media @large-desktop-down {
        top: -130px;
      }

      @media @desktop-down {
        font-size: 2em;
        top: -90px;
      }

      @media @phone-down {
        margin-left: 0;
        top: -55px;
        width: 100%;
        padding-left: 70px;
        font-size: 1em;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ant-btn-add-item {
  margin-bottom: 10px;
  @media @phone-down {
    width: 100%;
  }
}

.ant-tag {
  -webkit-box-shadow: 0px 0px 39px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 39px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 39px -9px rgba(0, 0, 0, 0.75);
  border: 1px solid #aaa !important;
  width: 80px;
  height: 20px !important;
}

.form-section {
  position: relative;
  border: 1px @theme-color dashed;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 5px;
  margin-bottom: 10px;

  .quill {
    background-color: white;
  }

  .dynamic-delete-button {
    //z-index: 2;
    //left: 10px;
    //position: absolute;
    //font-size: 24px;
    //width: 40px;
    //@media @tablet-down {
    //  left: unset;
    //  right: 10px;
    //}
  }
}

.ingredient-row {
  p {
    span {
      color: white !important;
    }
  }
}

.dynamic-delete-button {
  z-index: 2;
  right: 0px;
  position: absolute;
  font-size: 24px;
  margin: 0 !important;
  @media @tablet-down {
    right: 0px;
  }
}

.bg-success {
  background-color: #5bba5b !important;
  color: white;
  text-align: center;
}
.bg-alert {
  background-color: #ff7c79 !important;
  color: white;
  text-align: center;
}
.bg-info {
  background-color: #79c9ff !important;
  color: white;
  text-align: center;
}
.bg-warning {
  background-color: #ffc94a !important;
  color: white;
  text-align: center;
}

.table-cell-centered-icon {
  text-align: center !important;
}

.ant-table-cell {
  img {
    width: 50px;
    margin-right: 10px;
    transition: 0.3s ease-in-out opacity;
    opacity: 1;
    &:hover {
      transition: 0.3s ease-in-out opacity;
      opacity: 0.7;
      cursor: pointer;
    }
  }

  @media @tablet-down {
    button {
      min-width: 40px;

      span {
        text-align: center;
        margin: 4px auto 0;
        display: block;
      }
    }
  }
}

.form-stretch-columns {
  .ant-col-xs-24,
  .ant-col-xs-24 {
    max-width: 95%;
  }
}

.form-sub-section {
  background-color: lavender;
  padding-left: 10px;
  padding-top: 10px;

  label {
    white-space: pre-wrap;
    height: auto;
  }
}

.form-sub-sub-section {
  background-color: lightgrey;
  padding-left: 10px;
  padding-top: 10px;
}

.payment-tag {
  height: auto !important;
  padding: 5px;
  width: auto !important;
  box-shadow: none;
  padding-right: 20px;
  margin-bottom: 5px;
}

.form-saving {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #eeeeee;
  font-size: 18px;
  text-align: center;
  padding: 50px;
}

#admins-table {
  td {
    .ant-btn span:not(.anticon) {
      display: none;
    }
  }
}

.ant-layout-sider-children {
  padding-bottom: 85px;
}

.placeholder-label {
  padding-top: 5px;
  text-align: right;
  padding-right: 7px;
}

.linesheet-generator {
  .ant-list-item {
    transition: 0.3s ease-in-out opacity;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}