.filepicker-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
  transition: transform 0.2s ease-out;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    transition: transform 0.2s ease-in;
    transform: scale(1.1);
    cursor: pointer;
  }
}
