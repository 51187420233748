@import (once) './breakpoints.less';

.auth-container {
  .auth-container-row {
    height: 100vh;
    overflow: hidden;

    .auth-banner-col {
      position: relative;
      img {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        max-height: 100vh;
      }
    }

    .auth-form-col {
      background-color: #333;
      color: white;

      @media @tablet-down {
        background-image: url('../../assets/banner-auth.jpg');
        background-size: cover;
        padding-right: 0;

        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
        }
      }

      #auth-form {
        position: relative;
        z-index: 2;
        margin-top: 150px;
        @media @tablet-down {
          margin-top: 50px;
        }

        img {
          width: 100%;
        }

        .auth-buttons {
          text-align: right;
        }

        .reset-button {
          display: inline-block;
          color: #eee;
          float: right;
          transition: color ease-out 0.3s;
          &:hover {
            text-decoration: underline white;
            color: white;
            transition: color ease-in 0.3s;
            cursor: pointer;
          }
        }

        label,
        h1,
        #auth-subtitle {
          color: white;
        }

        h1 {
          font-size: 40px;
          margin-bottom: 0;
        }

        #auth-subtitle {
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
