.ant-layout-sider {
  padding-top: 85px;
  z-index: 2;

  &-collapsed {
    .ant-layout-sider-children {
      transition: all 0.25s ease-in-out;
      width: 85px;
      .ant-menu {
        transition: all 0.25s ease-in-out;
      }
    }
  }

  &-children {
    padding-bottom: 130px;
    position: fixed;
    width: 200px;
    .ant-menu {
      height: 100%;
      overflow: scroll;
      &-item {
        &-selected {
          a {
            color: white !important;
          }
        }

        &:hover {
          a {
            color: white;
          }
        }

        a {
          color: rgba(255, 255, 255, 0.65);
        }
      }
    }
  }
}
