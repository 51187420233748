@import (once) './breakpoints.less';

.ant-layout-header {
  width: 100%;
  z-index: 3;
  padding-left: 0 !important;
  position: fixed;
  -webkit-box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);

  @media @tablet-down {
    padding-right: 20px;
  }

  #cms-logo {
    width: 200px;
    display: inline-block;
  }

  img {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    max-width: 200px;
    max-height: 50px;
    width: auto;
    height: auto;
  }

  h1 {
    color: white;
    display: inline-block;
    font-size: 20px;
    position: relative;
    top: 5px;
    border-left: 1px solid white;
    padding-left: 10px;
    line-height: 2rem;
  }

  #nav-menu {
    font-size: 20px;
    float: right;
    color: white;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
}
