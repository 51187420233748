@import (once) '../../theme.less';

.app-container {
  min-height: 100vh;

  .empty-data {
    min-height: 300px;
    padding-top: 50px;
    color: #aaa;
    font-style: italic;
  }

  .chart-statistic {
    &-title {
      border-bottom: 1px solid @theme-color;
    }
    &-value {
      font-size: 40px;
    }
    &-subtitle {
      font-size: 15px;
    }
  }
}
