@import (once) './breakpoints.less';

.ant-layout-footer {
  margin-left: 200px;
  transition: margin-left 0.15s ease-in-out;
  &.collapsed {
    transition: margin-left 0.15s ease-in-out;
    margin-left: 80px;
  }
  z-index: 1;
  position: relative;
  padding-left: 200px;
  @media @tablet-down {
    padding-left: 80px;
    padding-right: 0;
  }
  img {
    width: 100px;
    position: relative;
    //top: -4px;
    margin-right: 5px;
  }
}
